import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Strong, Image, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Visit Ryold - Пивна спортсмена
			</title>
			<meta name={"description"} content={"Ласкаво просимо до \"Пивної спортсмена\"!\nМісце, де збираються любителі спорту, об'єднуються спортсмени, а дотик несподіванки захоплює кожного відвідувача. Пориньте у світ жвавих змагань, фірмових напоїв та незабутніх ночей. Ласкаво просимо до Visit Ryold - місця, де народжуються історії ваших вихідних."} />
			<meta property={"og:title"} content={"Головна | Visit Ryold - Пивна спортсмена"} />
			<meta property={"og:description"} content={"Ласкаво просимо до \"Пивної спортсмена\"!\nМісце, де збираються любителі спорту, об'єднуються спортсмени, а дотик несподіванки захоплює кожного відвідувача. Пориньте у світ жвавих змагань, фірмових напоїв та незабутніх ночей. Ласкаво просимо до Visit Ryold - місця, де народжуються історії ваших вихідних."} />
			<meta property={"og:image"} content={"https://visitryold.com/images/bg.jpg"} />
			<link rel={"shortcut icon"} href={"https://visitryold.com/images/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://visitryold.com/images/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://visitryold.com/images/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://visitryold.com/images/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://visitryold.com/images/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://visitryold.com/images/icon.png"} />
			<meta name={"msapplication-TileColor"} content={"https://visitryold.com/images/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg,rgba(0, 0, 0, 0.65) 0%,rgba(0, 0, 0, 0.5) 100%),url(https://visitryold.com/images/bg.jpg) center center/cover no-repeat"
			min-height="100vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Visit Ryold{" "}
					<br />
					{" "}Пивна спортсмена
				</Text>
				<Text
					margin="0px 100px 32px 100px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					Місце, де збираються любителі спорту, об'єднуються спортсмени, а дотик несподіванки захоплює кожного відвідувача. Пориньте у світ жвавих змагань, фірмових напоїв та незабутніх ночей.
				</Text>
				<Link
					href="/contact-us"
					padding="12px 24px 12px 24px"
					color="--dark"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Завітати
				</Link>
			</Box>
		</Section>
		<Section background="--color-darkL2" padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						display="flex"
						flex-direction="column"
						background="--color-light"
						color="--dark"
					>
						<Text
							as="h4"
							margin="12px 0"
							font="--headline3"
							color="--grey"
							letter-spacing="1px"
							text-transform="uppercase"
						>
							Унікальні пропозиції:
						</Text>
						<Text as="p" margin="12px 0" font="--lead">
							<Strong>
								Таємничий спортивний виклик:
							</Strong>
							{" "}Щовечора о 21:00 наше велике колесо обертається, щоб вибрати таємничий вид спорту. Це може бути що завгодно - від синхронного плавання до керлінгу! Якщо ви можете продемонструвати базові навички або знання з обраного виду спорту, пригощайтеся за наш рахунок!
							<br />
							<br />
							<Strong>
								Олімпійські ігри з елем:
							</Strong>
							{" "}Беріть участь у наших щотижневих олімпійських змаганнях Ale Olympics, що включають версії популярних видів спорту, пристосовані до умов бару. Ми говоримо про настільний теніс кришками від пляшок, фрізбі на підставках та солом'яний спис!
							<br />
							<br />
							<Strong>
								Інтерактивні столи:
							</Strong>
							{" "}Наші столи обладнані сенсорними екранами, які дозволяють вам грати з друзями у віртуальні спортивні ігри або отримувати доступ до цікавих спортивних дрібниць.
						</Text>
					</Box>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://visitryold.com/images/m3.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://visitryold.com/images/m2.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://visitryold.com/images/m1.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--secondary" font="--lead" md-margin="0px 0px 20px 0px">
					Про Нас
				</Text>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					Наша філософія
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 40px 0px" color="--light" font="--base">
					Спорт - це не просто гра для глядачів. Це про занурення, відчуття пульсу гри та розділення цих хвилюючих моментів. Незалежно від того, чи вболіваєте ви за кожен матч, чи є випадковим спостерігачем, чи просто шукаєте яскравого вечора, Visit Ryold прагне подарувати вам незабутні спогади. Тут ми втілюємо суть спортивної майстерності, конкуренції та єдності.
				</Text>
				<Text margin="0px 0px 0px 0px" color="--light" font="--base">
					Кожен сантиметр нашого закладу спроектований з подвійною метою - розважати і залучати. Кілька глядацьких секцій гарантують безперешкодний перегляд прямих трансляцій, в той час як окремі ігрові зони ваблять до себе, щоб розпочати власні змагальні протистояння. Наш декор - гармонійне поєднання позачасових матеріалів та сучасної естетики - відображає наше бачення: традиційне, але таке, що постійно розвивається.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" lg-padding="56px 0 56px 0" sm-padding="32px 0 32px 0" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 32px 0px"
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 33px 0px"
				display="flex"
				flex-direction="column"
				align-items="center"
				lg-padding="15px 15px 15px 15px"
				sm-margin="0px 0px 10px 0px"
				justify-content="center"
				width="100%"
			>
				<Text
					font="--headline2"
					color="--light"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Про Visit Ryold
				</Text>
				<Text
					font="--lead"
					color="--light"
					text-align="center"
					md-width="100%"
					opacity=".5"
					max-width="800px"
				>
					Зануртеся в серце Visit Ryold, де спортивний азарт зливається з атмосферою традиційного пабу. Тут ми пропонуємо більше, ніж просто місце, ми пропонуємо досвід.
				</Text>
			</Box>
			<Box
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				width="100%"
			>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					sm-width="100%"
					grid-column="1 / span 1"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="flex-start"
				>
					<Box
						width="100%"
						align-items="flex-start"
						display="flex"
						justify-content="flex-start"
						md-width="100%"
						md-margin="0px 0px 0px 0px"
						lg-width="100%"
					>
						<Image
							src="https://visitryold.com/images/7.jpg"
							border-radius="24px"
							max-width="100%"
							width="100%"
							object-fit="cover"
							lg-max-height="392px"
							object-position="0% 50%"
							sm-max-height="213px"
							height="522px"
						/>
					</Box>
					<Box
						width="100%"
						padding="16px 12px 16px 12px"
						display="flex"
						align-items="flex-start"
						justify-content="flex-start"
						md-width="100%"
						md-border-width="0px"
						flex-direction="column"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
							Суть нашого простору:
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light" opacity=".5">
							Кожен сантиметр нашого закладу спроектований з подвійною метою - розважати і залучати. Кілька глядацьких секцій гарантують безперешкодний перегляд прямих трансляцій, в той час як окремі ігрові зони ваблять до себе, щоб розпочати власні змагальні протистояння.
						</Text>
					</Box>
				</LinkBox>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					sm-width="100%"
					grid-column="2 / span 2"
					lg-grid-column="2 / span 1"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="flex-start"
				>
					<Box
						width="100%"
						align-items="flex-start"
						display="flex"
						justify-content="flex-start"
						md-width="100%"
						md-margin="0px 0px 0px 0px"
						lg-width="100%"
					>
						<Image
							src="https://visitryold.com/images/8.jpg"
							border-radius="24px"
							max-width="100%"
							width="100%"
							object-fit="cover"
							lg-max-height="392px"
							object-position="50% 50%"
							sm-max-height="213px"
							height="522px"
						/>
					</Box>
					<Box
						width="100%"
						padding="16px 12px 16px 12px"
						display="flex"
						align-items="flex-start"
						justify-content="flex-start"
						md-width="100%"
						md-border-width="0px"
						flex-direction="column"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
							Майстерність рідини:
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light" opacity=".5">
							Напій - це більше, ніж просто напій, це досвід. Наше меню може похвалитися універсальним асортиментом від майстерних місцевих рецептів до всесвітньо відомих напоїв. І, звичайно, наші фірмові коктейлі на спортивну тематику є свідченням нашої креативності. Наші "рідкі спортсмени" - досвідчені бармени - завжди готові змішати щось унікальне для вас.
						</Text>
					</Box>
				</LinkBox>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					lg-align-items="flex-start"
					sm-width="100%"
					grid-column="1 / span 2"
					lg-grid-column="1 / span 1"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="flex-start"
				>
					<Box
						width="100%"
						align-items="flex-start"
						display="flex"
						justify-content="flex-start"
						md-width="100%"
						md-margin="0px 0px 0px 0px"
						lg-width="100%"
					>
						<Image
							src="https://visitryold.com/images/6.jpg"
							border-radius="24px"
							max-width="100%"
							width="100%"
							object-fit="cover"
							lg-max-height="392px"
							object-position="50% 50%"
							sm-max-height="213px"
							height="522px"
						/>
					</Box>
					<Box
						width="100%"
						padding="16px 12px 16px 12px"
						display="flex"
						align-items="flex-start"
						justify-content="flex-start"
						md-width="100%"
						md-border-width="0px"
						flex-direction="column"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
							Наша команда - Неоспівані MVP:
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light" opacity=".5">
							Серце Visit Ryold - це його віддана команда. Ці чемпіони, незалежно від того, змішують вони напій, подають страву або налаштовують гру, гарантують, що кожен відвідувач відчує трепет від того, що він є частиною грандіозного видовища.
						</Text>
					</Box>
				</LinkBox>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					sm-width="100%"
					grid-column="3 / span 1"
					lg-grid-column="2 / span 1"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="flex-start"
				>
					<Box
						width="100%"
						align-items="flex-start"
						display="flex"
						justify-content="flex-start"
						md-width="100%"
						md-margin="0px 0px 0px 0px"
						lg-width="100%"
					>
						<Image
							src="https://visitryold.com/images/bg.jpg"
							border-radius="24px"
							max-width="100%"
							width="100%"
							object-fit="cover"
							lg-max-height="392px"
							object-position="70% 50%"
							sm-max-height="213px"
							height="522px"
						/>
					</Box>
					<Box
						width="100%"
						padding="16px 12px 16px 12px"
						display="flex"
						align-items="flex-start"
						justify-content="flex-start"
						md-width="100%"
						md-border-width="0px"
						flex-direction="column"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--light">
							Залучення ширшої аудиторії:
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--light" opacity=".5">
							Справжня спортивна майстерність виходить за рамки ігор. Ми глибоко вкорінені у формуванні почуття спільноти. Від підтримки місцевих ініціатив до проведення інклюзивних заходів, Visit Ryold - це більше, ніж паб, це рух.
						</Text>
					</Box>
				</LinkBox>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});